<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		>
			<template #extra-content>
				<booking-form :booking-form="defaults[locale].bookingForm" />
			</template>
		</heading-image>

		<main-content :title="page.title || page.header" :subtitle="page.subtitle" :content="page.content">
			<template #right-content>
				<div class="columns column6">
					<picture>
						<source :srcset="defaults[locale].homepage.introImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].homepage.introImage" />
						<img
							:src="defaults[locale].homepage.introImage"
							:alt="defaults[locale].homepage.introImageAlt"
							loading="lazy"
						/>
					</picture>
				</div>
			</template>
		</main-content>

		<section class="main-content usp">
			<div class="row align-center">
				<div
					v-for="(benefit, index) in defaults[locale].homepage.uniqueSellingPoints"
					:key="index"
					class="columns column4"
				>
					<font-awesome-icon :icon="benefit.icon" size="1x" />
					<div v-parse-links v-html="benefit.content" />
				</div>
			</div>
		</section>

		<contentblocks
			v-if="page.contentblocks && page.contentblocks.length"
			:contentblocks-content="page.contentBlockContent"
			:contentblocks="page.contentblocks"
		>
			<template #extra-content>
				<div class="row row-header">
					<div v-parse-links class="columns column12 align-center" v-html="page.contentBlockContent" />
				</div>
			</template>
		</contentblocks>

		<section class="main-content room-section">
			<div class="row row-header">
				<div
					v-parse-links
					class="columns column12 align-center"
					v-html="defaults[locale].homepage.sectionPromotionContent"
				/>
			</div>
			<div class="row rooms packages-section align-center">
				<div v-for="promo in promoblocksData" :key="promo.ID" class="columns column4">
					<div class="promo-wrapper">
						<nuxt-link :to="localePath(`/${promo.link}`)">
							<div class="content">
								<h3>{{ promo.header }}</h3>
								<div v-parse-links v-html="promo.content" />
								<div class="button">{{ promo.button }}</div>
							</div>
							<div class="image-gradient" />
							<picture>
								<source :srcset="promo.imageWebp" type="image/webp" />
								<source :srcset="promo.image" />
								<img :src="promo.image" :alt="promo.imageAlt" loading="lazy" />
							</picture>
						</nuxt-link>
					</div>
				</div>
			</div>
		</section>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>

		<pre-footer v-if="page.footerSection && page.footerSection.length" :footer-section="page.footerSection[0]" />
		<newsletter
			v-if="page.showNewsletter"
			:header="defaults[locale].newsletterForm.header"
			:content="defaults[locale].newsletterForm.content"
			:email="defaults[locale].newsletterForm.email"
			:button="defaults[locale].newsletterForm.button"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
h1 {
	margin: 25px 0;
}

.row-header {
	max-width: 1000px;
	margin: 0 auto 40px;
}

.usp {
	background: var(--background-color-light);

	svg {
		color: var(--background-color-dark);
		font-size: 44px;
		margin: 5px 0 20px;
	}
}

@media (max-width: 960px) {
	.usp .column4 {
		width: 100%;
		margin: 20px auto;
	}

	.packages-section .column4 {
		margin: 20px auto;
	}
}
</style>
